


























































































import {
  computed,
  defineComponent,
  ref,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import { ProductList, useImage, useProduct } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { Product } from '~/modules/catalog/product/types';
import { useSection } from '~/diptyqueTheme/composable/useSection';
import BlockWrapper from '~/diptyqueTheme/components/templates/BlockWrapper.vue';
import VaimoProductCard from 'organisms/VaimoProductCard.vue';
import VaimoSlider from 'molecules/VaimoSlider.vue';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';

export default defineComponent({
  name: 'ProductsSection',
  components: {
    BlockWrapper,
    VaimoProductCard,
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    Banner: () => import('templates/sections/Banner.vue'),
    VaimoSlider
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const carouselKey = ref(0);
    const { getProductList, loading } = useProduct();
    const { getMagentoImage } = useImage();
    const { addItemToCart, isInCart, loading: addLoading } = useAddToCart();
    const {
      headingData,
      truncatedTextData,
      getContentAlignClasses,
      getBlockElementClasses
    } = useSection(props.sectionData);
    const activeTab = ref<string>('');
    const sku = ref<string[]>([]);
    const setActiveTab = async function (tab: {
      groupTitle: string;
      sku: string[];
    }) {
      sku.value = tab?.sku;
      activeTab.value = tab.groupTitle;
    };

    watch(activeTab, async () => {
      if (sku.value.length) {
        await getProductsBySku(sku.value);
      }
    });

    const products = ref([]);
    const getProductsBySku = async (sku_array: string[]) => {
      try {
        const data: ProductList = await getProductList({
          filter: {
            sku: {
              in: sku_array
            }
          }
        });
        products.value = data?.items;
      } catch (e) {
        console.error(e);
      }
    };

    watch(products, async () => {
      carouselKey.value += 1;
    });

    const getSectionDisplay = computed<string>(() => {
      const displayMap = {
        slider: 'slider',
        'slider tabs': 'with_tabs'
      };

      return displayMap[props.sectionData?.display];
    });

    const getProductGroupsCollection = computed<object[] | []>(() => {
      return props.sectionData?.productGroupsCollection?.items;
    });

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    const getProductThumbnail = (product: Product): string => {
      return getMagentoImage(productGetters.getProductThumbnailImage(product));
    };

    const getProductPrice = (product: Product): number => {
      return productGetters.getPrice(product).regular;
    };

    useFetch(async () => {
      const firstGroupSku: string[] =
        props.sectionData?.productGroupsCollection?.items[0]?.sku ||
        props.sectionData?.productGroup?.sku;
      activeTab.value =
        props.sectionData?.productGroupsCollection?.items[0]?.groupTitle;
      await getProductsBySku(firstGroupSku);
    });

    const addLoadingSKU = ref(null);

    const addToCart = (item) => {
      addLoadingSKU.value = item.product.sku;
      addItemToCart(item);
    };

    const getLoadingFlag = (product) => {
      return addLoading.value && addLoadingSKU.value === product.sku;
    };

    const getBannerData = computed<object>(() => {
      return props.sectionData?.banner;
    });

    const setCarouselSettings = () => {
      const desktopTemplate =
        props?.sectionData?.desktopBlockSettings?.template;
      if (
        (desktopTemplate && desktopTemplate.includes('left')) ||
        (desktopTemplate && desktopTemplate.includes('right'))
      ) {
        return {
          arrows: true,
          dots: false,
          variableWidth: false,
          edgeFriction: 0.2,
          infinite: false,
          slidesToShow: 2.58,
          slidesToScroll: 2,
          speed: 500,
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToScroll: 1,
                slidesToShow: 1.3
              }
            }
          ]
        };
      }
      return {
        arrows: true,
        dots: false,
        variableWidth: false,
        edgeFriction: 0.2,
        infinite: false,
        speed: 500,
        slidesToShow: 4.175,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 3.2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.3,
              slidesToScroll: 1
            }
          }
        ]
      };
    };

    const setCarouselClasses = (products) => {
      const productsQty = products.length;
      const desktopTemplate =
        props?.sectionData?.desktopBlockSettings?.template;
      if (
        (desktopTemplate && desktopTemplate === 'banner left') ||
        (desktopTemplate && desktopTemplate === 'banner right')
      ) {
        return 'products-slider';
      }
      // eslint-disable-next-line no-magic-numbers
      if (productsQty <= 4) {
        return 'products-slider products-slider__centered';
      }
      return 'products-slider';
    };
    const moveContextualCursor = (event) => {
      event.currentTarget.querySelector('.contextual-cursor').style.transform =
        'translate(' + event.offsetX + 'px, ' + event.offsetY + 'px)';
    };

    return {
      setActiveTab,
      products,
      getBannerData,
      addLoading,
      loading,
      productGetters,
      customProductGetters,
      getProductPrice,
      getProductThumbnail,
      headingData,
      getProductLink,
      getMagentoImage,
      addToCart,
      getLoadingFlag,
      truncatedTextData,
      isInCart,
      activeTab,
      getSectionDisplay,
      getProductGroupsCollection,
      getContentAlignClasses,
      setCarouselSettings,
      setCarouselClasses,
      moveContextualCursor,
      carouselKey,
      elemClasses: getBlockElementClasses,
      validateFraction
    };
  }
});
