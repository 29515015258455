


















































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { useLink } from '~/diptyqueTheme/composable/useLink';

export default defineComponent({
  name: 'CollectionSlider',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoSlider,
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const { getBannerFunction } = useDeclination(props.sectionData);
    const getItems = computed(() =>
      (props.sectionData?.itemsCollection?.items ?? []).filter(
        (item) => !!item?.sys
      )
    );
    return {
      getItems,
      getBannerFunction,
      normalizeLink
    };
  }
});
