














import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import spotifyPlayerDimensions from '~/diptyqueTheme/config/spotifyPlayerDimensions';
import { SpotifySectionData } from '~/diptyqueTheme/types/contentful/contentTypes/spotifyPlayer';
export default defineComponent({
  name: 'SpotifyPlayer',
  props: {
    sectionData: {
      required: true,
      type: Object as PropType<SpotifySectionData>
    }
  },

  setup(props) {
    const spotifyData = computed<
      Omit<SpotifySectionData, 'id' | 'sys' | 'entryTitle' | 'isDefaultTheme'>
    >(() => ({
      link: props.sectionData.link.includes('track')
        ? trackLink.value
        : widgetLink.value,
      width: props.sectionData.width || spotifyPlayerDimensions.DEFAULT_WIDTH,
      height: props.sectionData.height || spotifyPlayerDimensions.DEFAULT_HEIGHT
    }));

    const appendSearchParams = (url: URL) => {
      url.searchParams.append('utm_source', 'generator');
      url.searchParams.append(
        'theme',
        props.sectionData.isDefaultTheme ? '0' : '1'
      );

      return url.toString();
    };

    const widgetLink = computed(() => {
      const url = new URL(props.sectionData.link);

      return appendSearchParams(url);
    });

    const trackLink = computed(() => {
      const regex = /track\/([a-zA-Z0-9]+)/;
      const match = props.sectionData.link.match(regex);

      if (match) {
        const trackId = match[1];
        const url = new URL(`https://open.spotify.com/embed/track/${trackId}`);

        return appendSearchParams(url);
      }

      return null;
    });

    return {
      spotifyData
    };
  }
});
