


























































































import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import merge from 'lodash.merge';

import { BundleProduct, ProductDetailsQuery, useProduct } from '~/composables';
import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import type { BundleOption } from '~/diptyqueTheme/stores/bundleProduct/types';
import {
  getBundleProducts,
  getName as getProductName,
  getPrice
} from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import type { Visual } from '~/diptyqueTheme/types/contentful';
import { useAlternativeVisual } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'BoxSlider',
  components: {
    VaimoSlider,
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    BoxSliderCard: () =>
      import('templates/sections/BundleProducts/parts/BoxSliderCard.vue'),
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getProductList } = useProduct();
    const { addItem, loading } = useCart();
    const {
      app: { $vsf, i18n }
    } = useContext();
    const { applyAltVisualsByProduct } = useAlternativeVisual();
    const product = ref<Product | null>(null);
    const bundleProducts = ref<BundleProduct['items'] | undefined>([]);
    const selectedBundleProducts = ref([]);
    const ctfProductItem = computed(() => {
      const products = props.sectionData?.itemsCollection?.items?.filter(
        (item) => item?.__typename.toLowerCase() === 'productitem'
      );
      return products.length ? products[0] : null;
    });

    const productDescription = computed(
      () => product.value?.plp_description || ''
    );

    const getFieldsVisibilityArr = computed(
      () => ctfProductItem.value?.visibility ?? []
    );

    const altVisuals = computed(
      () => props.sectionData?.altvisCollection?.items ?? []
    );

    const getBoxSliderCounterText = computed(() => {
      return i18n.t('Select your {quantity} products', {
        quantity: getNumberOfSets.value
      });
    });

    const getNumberOfSets = computed(() => +product.value?.number_of_sets);

    const getProductPrice = computed(() => {
      if (product?.value) {
        return getPrice(product.value).regular || 0;
      }
    });

    const getSliderDesktopQty = computed(() => {
      return getSectionData.value?.visual?.posD === 'Left' ||
        getSectionData.value?.visual?.posD === 'Right'
        ? 2
        : 4;
    });

    const getSectionData = computed(() => {
      const _sectionData = props.sectionData;
      // Visual
      if (_sectionData.visual) {
        if (
          !_sectionData.visual.ratio ||
          _sectionData.visual.ratio === 'Default for current module'
        ) {
          _sectionData.visual.ratio = '1:1';
        }
        if (
          !_sectionData.visual.ratioD ||
          _sectionData.visual.ratioD === 'Default for current module'
        ) {
          _sectionData.visual.ratioD = '3:4';
        }
        if (
          !_sectionData.visual.pos ||
          _sectionData.visual.pos === 'Default for current module'
        ) {
          _sectionData.visual.pos = 'Top';
        }
        if (
          !_sectionData.visual.posD ||
          _sectionData.visual.posD === 'Default for current module'
        ) {
          _sectionData.visual.posD = 'Left';
        }
      }

      // Buttons
      const addToCartText = '#addBundleToCart';
      const hasAddToCartButton = !!_sectionData.buttonCollection?.items?.find(
        (button) => button.link === addToCartText
      );
      if (!hasAddToCartButton) {
        if (!_sectionData.buttonCollection?.items) {
          _sectionData.buttonCollection = {
            items: []
          };
        }
        _sectionData.buttonCollection?.items?.unshift({
          __typename: 'Button',
          text: i18n.t('Add gift box to bag'),
          link: addToCartText,
          type: 'Transactional CTA',
          layerCont: null
        });
      }
      const addToCartButton = _sectionData.buttonCollection?.items?.find(
        (button) => button.link === addToCartText
      );
      if (addToCartButton) {
        addToCartButton.disabled = !isMaxProductsAdded.value || loading.value;
        addToCartButton.preventDefault = true;
      }
      return _sectionData;
    });

    const getDynamicPriceText = (price) => {
      return i18n.t('From {price}', {
        price
      });
    };

    const getProductTitleAndDescription = (product?: Product) => {
      const description =
        props.sectionData?.descr || productDescription.value || '';
      const heading =
        props.sectionData?.heading || getProductName(product) || '';

      return {
        description,
        heading
      };
    };

    const isMaxProductsAdded = computed(() => {
      const isBundleFullyConfigured =
        selectedBundleProducts.value.length === getNumberOfSets.value;
      const isQuantitySumEqualSets =
        getQuantitySum.value === getNumberOfSets.value;

      return isBundleFullyConfigured || isQuantitySumEqualSets;
    });

    const getQuantitySum = computed(() => {
      return selectedBundleProducts.value.reduce((memo, cur) => {
        memo = memo + cur.quantity;
        return memo;
      }, 0);
    });

    const getBaseSearchQuery = () => ({
      filter: {
        url_key: {
          eq: product.value?.url_key
        }
      },
      configurations: []
    });

    const getProduct = async () => {
      if (!ctfProductItem.value?.sku) {
        return false;
      }

      try {
        const data = await getProductList({
          filter: {
            ['sku']: {
              in: [ctfProductItem.value.sku]
            }
          },
          pageSize: 1
        });
        product.value = data?.items?.[0] as Product;
      } catch (error) {
        console.error('BoxSlider getProduct()', error);
      }
    };
    const fetchProductExtendedData = async (
      searchQuery = getBaseSearchQuery()
    ) => {
      const { data } =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore "getProductPriceBySku" dynamically added by custom-middleware
        await $vsf.$magento.api.getProductPriceBySku<ProductDetailsQuery>(
          searchQuery
        );

      if (product.value && data?.products?.items?.length) {
        product.value = merge(
          {},
          product.value,
          data?.products?.items?.[0] as Product
        );
      }
      bundleProducts.value = getBundleProducts(product.value)?.filter(
        (bundle) => bundle?.options.length > 0
      );

      bundleProducts.value.forEach((item) => {
        let product = item?.options?.[0]?.product;
        if (product) {
          product = applyAltVisualsByProduct(
            product as Product,
            altVisuals.value as Visual[]
          );
        }
      });
    };

    const getCurrentBundle = (id: string): BundleOption => {
      return selectedBundleProducts.value.find((bundle) => bundle.id === id);
    };

    const getDynamicPrice = computed(() => {
      return selectedBundleProducts.value.reduce((memo, cur) => {
        memo += cur.price * cur.quantity;
        return memo;
      }, 0);
    });

    const isDynamicPrice = computed(
      () => product.value?.price_type === 'DYNAMIC'
    );

    const updateBundleProduct = (data) => {
      const { id, quantity, price } = data;
      const currentBundle = getCurrentBundle(id);
      if (!currentBundle) {
        const newBundle = {
          id,
          quantity,
          price
        };
        selectedBundleProducts.value.push(newBundle);
      } else if (currentBundle && quantity === 0) {
        const index = selectedBundleProducts.value.findIndex(
          (product) => product.id === id
        );
        selectedBundleProducts.value.splice(index, 1);
      } else {
        currentBundle.quantity = quantity;
      }
    };
    const updateQuantity = (data) => {
      const { id, quantity } = data;
      if (quantity <= getQuantitySum.value) {
        updateBundleProduct(data);
      } else if (
        quantity >= getQuantitySum.value &&
        quantity <= getNumberOfSets.value
      ) {
        updateBundleProduct(data);
      } else {
        return;
      }
      if (getQuantitySum.value > getNumberOfSets.value) {
        updateBundleProduct({
          id,
          quantity: quantity - (getQuantitySum.value - getNumberOfSets.value)
        });
      }
    };

    const addBundleProductsToCart = async () => {
      if (loading.value || !isMaxProductsAdded.value) return;
      try {
        if (isDynamicPrice.value) {
          product.value.dynamic_price = getDynamicPrice.value;
        }
        await addItem({
          product: {
            ...product.value,
            bundle_options: selectedBundleProducts.value.map((product) => ({
              uid: product.id,
              value: product.quantity
            }))
          } as BundleProduct,
          quantity: 1
        });
        selectedBundleProducts.value = [];
      } catch (e) {
        console.error(e);
      }
    };

    useFetch(async () => {
      await getProduct();
      await fetchProductExtendedData();
    });

    return {
      product,
      getBoxSliderCounterText,
      getFieldsVisibilityArr,
      getSectionData,
      updateQuantity,
      loading,
      getQuantitySum,
      getSliderDesktopQty,
      addBundleProductsToCart,
      getDynamicPrice,
      isDynamicPrice,
      getProductTitleAndDescription,
      isMaxProductsAdded,
      getDynamicPriceText,
      validateFraction,
      getProductName,
      getNumberOfSets,
      getProductPrice,
      selectedBundleProducts,
      bundleProducts,
      altVisuals
    };
  }
});
