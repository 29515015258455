



























































































import { defineComponent, nextTick, ref, watch } from '@nuxtjs/composition-api';
import { SfBadge, SfButton, SfLink } from '@storefront-ui/vue';
import VaimoIcon from 'atoms/VaimoIcon.vue';
import LazyHydrate from 'vue-lazy-hydration';

import Notification from '~/diptyqueTheme/components/Notification.vue';
import VaimoPromoBanner from '~/diptyqueTheme/components/organisms/VaimoPromoBanner.vue';
import { useCheckoutStore } from '~/diptyqueTheme/modules/checkout/store/checkoutData';

export default defineComponent({
  name: 'VaimoTopHeaderMobile',
  components: {
    VaimoIcon,
    LazyHydrate,
    SfLink,
    SfButton,
    SfBadge,
    VaimoPromoBanner,
    Notification
  },
  props: {
    isSimplified: {
      type: Boolean,
      default: false
    },
    cartProductsQuantity: {
      type: Number,
      default: 0
    }
  },
  emits: ['searchStart', 'menuToggle', 'cartToggle', 'accountToggle'],
  setup(props) {
    const headerMobileRef = ref(null);
    const promoBannerRef = ref(null);
    const headerMobileContentRef = ref(null);
    const checkoutStore = useCheckoutStore();

    const adjustHeaderHeight = () => {
      nextTick(() => {
        const promoHeight = promoBannerRef.value?.$el?.clientHeight;
        const headerContentHeight = headerMobileContentRef.value?.clientHeight;
        let allHeaderContentHeight = false;

        if (promoHeight && headerContentHeight) {
          allHeaderContentHeight = promoHeight + headerContentHeight;
        }
        if (allHeaderContentHeight) {
          headerMobileRef.value.style.height = `${allHeaderContentHeight}px`;
        } else {
          headerMobileRef.value.style.height =
            `${headerContentHeight}px` ?? '83px';
        }

        allHeaderContentHeight
          ? storeHeaderHeight(allHeaderContentHeight)
          : storeHeaderHeight(headerContentHeight);
      });
    };

    const storeHeaderHeight = (height) => {
      checkoutStore.updateHeaderHeight(height);
    };

    watch(
      () => props.isSimplified,
      (isSimplified) => {
        if (isSimplified) {
          adjustHeaderHeight();
        }
      }
    );

    return {
      headerMobileRef,
      promoBannerRef,
      headerMobileContentRef
    };
  }
});
