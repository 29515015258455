import { render, staticRenderFns } from "./KlevuProduct.vue?vue&type=template&id=582b3de6&scoped=true&"
import script from "./KlevuProduct.vue?vue&type=script&lang=js&"
export * from "./KlevuProduct.vue?vue&type=script&lang=js&"
import style0 from "./KlevuProduct.vue?vue&type=style&index=0&id=582b3de6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582b3de6",
  null
  
)

export default component.exports