<template>
  <div
    v-if="!hideSlider()"
    class="product-slider"
    :class="{ isLoading: !isProductsLoadedFlag }"
  >
    <LoadWhenVisible>
      <VaimoHeading
        v-if="heading"
        :heading="heading"
        :heading-link="sectionData.headingLink"
        :animated-heading="false"
        :aria-label="heading"
        class="module__heading mb-md px-sm text-center"
      />
    </LoadWhenVisible>
    <LoadWhenVisible>
      <VaimoTabs v-if="tabs && tabs.length" :tabs="tabs">
        <template v-for="tab in tabs" #[tab.id]>
          <VaimoProducts
            v-if="tab.data.sku || tab.data.type !== 'list of sku'"
            :key="uniqueKey + '-' + tab.id"
            :unique-key="uniqueKey + '-' + tab.id"
            :sku-list="tab.data.sku"
            :display="tab.data.display"
            :sku-list-type="tab.data.type"
            :nosto-id="tab.data.nostoId"
            :wrapper-props="{ slider: { mobileShift: 25 } }"
            :alt-visuals="tab.data.altVisuals"
            @productsLoaded="setLoadingFlag"
          />
        </template>
      </VaimoTabs>
    </LoadWhenVisible>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref
} from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useProductGroup } from '~/diptyqueTheme/composable/useProductGroup';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import groupTypes from '~/diptyqueTheme/config/productGroupTypes';

export default defineComponent({
  name: 'ProductSlider',
  components: {
    LoadWhenVisible,
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue'),
    VaimoTabs: () => import('molecules/VaimoTabs.vue')
  },
  props: {
    uniqueKey: {
      required: false,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    },
    config: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const { getTransformedData } = useProductGroup(props.config);
    const heading = computed(() => props.sectionData?.heading);
    const productsGroups = ref([]);
    const isProductsLoadedFlag = ref(false);

    onBeforeMount(async () => {
      await transformedData();
    });

    const hideSlider = () => {
      return (
        !productsGroups.value ||
        (productsGroups.value.length === 1 &&
          productsGroups.value[0].type === groupTypes.NOSTO &&
          ((Array.isArray(productsGroups.value[0]?.sku) &&
            productsGroups.value[0]?.sku.length === 0) ||
            !productsGroups.value[0]?.sku))
      );
    };

    const transformedData = async () => {
      productsGroups.value = await getTransformedData(
        props.sectionData?.productGroupsCollection?.items
      );
    };

    const setLoadingFlag = (flag) => {
      isProductsLoadedFlag.value = flag;
    };

    const getGroupDisplay = (group) => {
      if (group.type === groupTypes.UPSELLS && !isDesktop.value) {
        return 'grid';
      }
      return 'slider';
    };
    const tabs = computed(() => {
      const tabs =
        productsGroups.value?.map((group, i) => ({
          title: group.groupTitle,
          id: 'tab' + i,
          data: {
            display: getGroupDisplay(group),
            sku: group.sku,
            type: group.type,
            nostoId: group.nostoSlot,
            altVisuals: group?.altvisCollection?.items ?? []
          }
        })) || [];

      return tabs.filter(
        (tab) => tab.data.sku?.length || tab.data.type !== 'list of sku'
      );
    });

    return {
      heading,
      tabs,
      setLoadingFlag,
      isProductsLoadedFlag,
      hideSlider
    };
  }
});
</script>

<style lang="scss" scoped>
.product-slider {
  &.isLoading {
    height: 100vh;
  }
}
</style>
