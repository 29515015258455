<template>
  <div class="adyen-payment-provider__wrapper">
    <transition name="fade">
      <slot v-if="isMalformed" name="price-mismatch">
        <div ref="errorContainer" class="adyen-payment-provider__error">
          <span v-if="responseMessage" data-cs-capture>
            {{ responseMessage }}
          </span>
          <span v-else data-cs-capture>
            {{ $t('Something went wrong. Please try again!') }}
          </span>
        </div>
      </slot>
    </transition>
    <transition name="fade">
      <slot v-if="!isClientKeyAvailable" name="client-key-mismath">
        <div
          ref="errorContainer"
          class="adyen-payment-provider__error"
          data-cs-capture
        >
          {{
            $t(
              'Client Key is not available. Please check with the administrator.'
            )
          }}
        </div>
      </slot>
    </transition>
    <div ref="dropinContainer" class="adyen-payment-provider__container"></div>
    <slot
      v-if="!isPayButtonEnabled"
      name="pay-button"
      :submit-payment="submitPaymentButton"
    />
  </div>
</template>
<script>
import AdyenCheckout from '@adyen/adyen-web/dist/es.modern';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useAdyen } from '~/integrations/adyen/composables/useAdyen';
import { useCheckoutStore } from '~/modules/checkout/store/checkoutData';
import { useUser } from '~/composables';
import userGetters from '~/modules/customer/getters/userGetters';

// TODO: DPTQ-2330 Google Pay
// - load google pay script if enabled

export default defineComponent({
  name: 'AdyenPaymentProvider',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    showPayButton: {
      type: Boolean,
      default: true
    },
    // Called just before calling a `submitPayment`.
    // You can modify the payload inside of it before it's sent to Adyen.
    beforePay: {
      type: Function,
      default: (stateData) => stateData
    },
    // afterPay props expect a callback function called after the payment is authorized and the order is placed.
    // Inside this callback, you can redirect the user to the order confirmation page and clear the cart.
    afterPay: {
      type: Function,
      // eslint-disable-next-line
      default: (order) => {}
    },
    onChange: {
      type: Function,
      // eslint-disable-next-line
      default: ({ state, component }) => {}
    },
    onError: {
      type: Function,
      // eslint-disable-next-line
      default: ({ action, error }) => {}
    }
  },
  emits: ['on-3ds-loaded', 'on-method-deleted', 'on-method-selected'],
  setup(props, { emit }) {
    const dropinContainer = ref(null);
    const dropinComponent = ref(null);
    const errorContainer = ref(null);
    const isMalformed = ref(false);
    const isClientKeyAvailable = ref(true);
    const responseMessage = ref('');
    const isAuthenticated = computed(() => props.isAuthenticated);
    const isPayButtonEnabled = computed(() => props.showPayButton);
    const { i18n } = useContext();
    const { sendBooxiExpiredMessage, checkIsBooxiExpired } = useBooxi();
    const { user } = useUser();

    const {
      loading,
      error,
      orderDetails,
      getDropinConfig,
      getPaymentMethods,
      submitPayment,
      submitAdditionalDetails,
      deleteStoredMethod
    } = useAdyen();

    /**
     * PayPal Smart Payment Buttons doesn't support the .submit() method.
     * See: https://docs.adyen.com/online-payments/web-drop-in/optional-configuration#optional-adyen-checkout-configuration
     */
    const submitPaymentButton = async () => {
      if (!loading.value && dropinComponent.value) {
        dropinComponent.value.submit();
      }
    };

    /** onDisableStoredPaymentMethod callback
     *  https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=6.2.0#step-4-configure-drop-in */
    const deleteStoredMethodHandler = async (storedPaymentMethodId, resolve, reject) => {
      const payload = {
        stored_payment_method_id: storedPaymentMethodId,
        customer_email: userGetters.getEmailAddress(user.value),
        store_code: i18n.localeProperties.code
      };
      const { success, message } = await deleteStoredMethod(payload);

      if (!success || error.value.deleteStoredMethod) {
        props.onError({
          action: 'Remove stored method',
          error: {
            message: `Failed to remove stored card. Reason: ${message}`
          }
        });
        reject();

        return;
      }

      resolve();
      emit('on-method-deleted');
    };

    const responseHandler = (response, component, orderId = null) => {
      if (response.action) {
        if (response.action?.type === 'threeDS2') {
          emit('on-3ds-loaded');
        }

        return component.handleAction(response.action);
      }

      if (response.resultCode === 'Authorised') {
        return props.afterPay({
          ...orderDetails.value,
          ...(orderId ? { order: { id: orderId } } : {})
        });
      }

      props.onError({ action: 'Response Handler', error: response });
      const errorMessage = i18n.t('Something went wrong. Please try again!');
      return onPaymentError(errorMessage);
    };

    const onPaymentError = (message) => {
      isMalformed.value = true;
      responseMessage.value = message;
      dropinComponent.value.setStatus('ready');
    };

    // Called when the shopper selects the Pay button and the payment details are valid.
    const onSubmit = async (state, component) => {
      if (checkIsBooxiExpired()) {
        sendBooxiExpiredMessage();
        return;
      }
      if (!loading.value && state.isValid) {
        isMalformed.value = false;
        dropinComponent.value.setStatus('loading');
        const stateData = await props.beforePay(state.data);
        await submitPayment(stateData);

        if (error.value.submitPayment) {
          props.onError({
            action: 'Submit Payment',
            error: error.value.submitPayment
          });

          const errorMessage = error.value.submitPayment?.data?.data?.message;
          return onPaymentError(errorMessage);
        }

        responseHandler(orderDetails.value, component);
      }
    };

    // Create an event handler, called when a change happens in the payment form.
    const onChange = async (state, component) => {
      await props.onChange({ state, component });
    };

    // Called when the shopper has completed any additional action required by the payment method,
    // for example scanning a QR code or authenticating a payment with 3D Secure.
    // On test envs - in case wrong "password" is entered during 3DSecure -> component is blocked.
    // Test card can be used for error emulation: 5201 2829 9900 5515, 03/30, 737
    const onAdditionalDetails = async (state, component) => {
      const orderId = orderDetails.value.order.id;

      await submitAdditionalDetails({
        ...state.data,
        orderId
      });

      const submitAdditionalDetailsResponse = orderDetails.value;

      if (error.value.submitAdditionalDetails) {
        props.onError({
          action: 'Submit Additional Details',
          error: error.value.submitAdditionalDetails
        });

        const errorMessage = i18n.t(
          '{message}. Please try again! Order ID: {orderId}',
          {
            message:
              error.value.submitAdditionalDetails?.data?.data?.message || '',
            orderId: orderId
          }
        );
        return onPaymentError(errorMessage);
      }

      responseHandler(submitAdditionalDetailsResponse, component, orderId);
    };

    // Create an event handler, called when an error occurs in Drop-in.
    const onError = async (error) => {
      // eslint-disable-next-line no-return-await
      return await props.onError({
        action: 'Dropin Component',
        error
      });
    };

    const initDropinComponent = async () => {
      const { paymentMethodsResponse, paymentMethodsExtraDetails } =
        await getPaymentMethods();

      // Handle api/adyen/getPaymentMethodsRequest errors
      if (error.value.getPaymentMethods) {
        props.onError({
          action: 'getPaymentMethods',
          error: error.value.getPaymentMethods
        });

        return;
      }

      // Create an instance of AdyenCheckout using the configuration object
      const dropinConfig = getDropinConfig(
        paymentMethodsResponse,
        paymentMethodsExtraDetails,
        isAuthenticated.value,
        onSubmit,
        onChange,
        onAdditionalDetails,
        onError
      );

      if (!dropinConfig?.clientKey) {
        isClientKeyAvailable.value = false;
      }

      const checkout = await AdyenCheckout({
        ...dropinConfig,
        showPayButton: isPayButtonEnabled.value
      });

      // Create an instance of Drop-in and mount it to the container
      dropinComponent.value = checkout
        .create('dropin', {
          showRemovePaymentMethodButton: true,
          onDisableStoredPaymentMethod: deleteStoredMethodHandler,
          onSelect: () => {
            emit('on-method-selected');
          }
        })
        .mount(dropinContainer.value);
    };

    onMounted(async () => {
      if (!process.server) {
        await initDropinComponent();
      }
    });

    const { headerHeight, stepsHeaderHeight } = useCheckoutStore();

    watch(
      [isMalformed, isClientKeyAvailable],
      async ([newIsMalformed, newIsClientKeyAvailable]) => {
        if (newIsMalformed || !newIsClientKeyAvailable) {
          await nextTick();
          if (errorContainer?.value) {
            const offset = headerHeight + stepsHeaderHeight;
            const elementPosition =
              errorContainer.value.getBoundingClientRect().top +
              window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }
      }
    );

    return {
      dropinContainer,
      submitPaymentButton,
      isPayButtonEnabled,
      orderDetails,
      isMalformed,
      responseMessage,
      isClientKeyAvailable,
      error,
      errorContainer
    };
  }
});
</script>
<style lang="scss">
@import '@adyen/adyen-web/dist/adyen.css';
@import '@/integrations/adyen/styles/dropin.scss';
</style>
<style lang="scss" scoped>
.adyen-payment-provider {
  &__wrapper {
    padding: var(--spacer-base) 0;
  }
  &__container {
    margin: var(--spacer-base) 0;
  }
  &__error {
    color: var(--c-danger);
    font-size: var(--font-size-small);
    padding: var(--spacer-base);
    margin: var(--spacer-base) 0;
    background: #f7f8f9;
    border: 1px solid #e6e9eb;
    border-radius: 12px;
  }
}
</style>
