




























































































































































































































































































































































































































































































































































































































































































































































import { debounce } from 'lodash-es';

declare var google: any;
import type { ComputedRef } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  useFetch,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfHeading, SfInput, SfSelect } from '@storefront-ui/vue';
import VaimoPhone from 'organisms/VaimoPhone.vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { alpha, digits, max, min, required } from 'vee-validate/dist/rules';

import { useConfig, useCountrySearch, useStore } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useCustomTabFocus } from '~/diptyqueTheme/composable/useCustomTabFocus';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { useZip2Address } from '~/diptyqueTheme/composable/useZip2Address';
import type { CheckoutAddressForm } from '~/diptyqueTheme/helpers/checkout/address';
import {
  addressFromApiToForm,
  addressFromCustomerToShippingCart,
  findUserAddressIdenticalToSavedCartAddress,
  getInitialCheckoutAddressForm
} from '~/diptyqueTheme/helpers/checkout/address';
import {
  charsRegex,
  doubleQuotesRegex,
  hiraganaRegex,
  kanaRegex,
  katakanaRegex,
  nameRegex,
  phoneRegex,
  phoneRegexJP,
  postcodeJP
} from '~/diptyqueTheme/helpers/regexes';
import { useAvailableShippingMethodsStore } from '~/diptyqueTheme/stores/checkout';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { useSelectedShippingMethodStore } from '~/diptyqueTheme/stores/selectedShippingMethod';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { Logger } from '~/helpers/logger';
import useShipping from '~/modules/checkout/composables/useShipping';
import useAddresses from '~/modules/customer/composables/useAddresses';
import useUser from '~/modules/customer/composables/useUser';
import useUserAddress from '~/modules/customer/composables/useUserAddress';
import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type {
  AvailableShippingMethod,
  CartAddressInterface,
  Country,
  Customer,
  CustomerAddress
} from '~/modules/GraphQL/types';
import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('max', {
  ...max,
  message: '30 characters maximum'
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number'
});
extend('alpha', {
  ...alpha,
  validate(value) {
    return {
      valid: /^[a-zA-ZÀ-Ÿ0-9\s., &#/!\'-]+$/.test(value)
    };
  },
  message: 'Use only alphabetic letters'
});

extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});

extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('phone-jp', {
  message:
    'Please enter only half-width numbers without hyphens and up to 11 digits.',
  validate(value) {
    return {
      valid: phoneRegexJP.test(value.replace(/ /g, ''))
    };
  }
});

extend('restrictions', {
  params: ['target'],
  //@ts-ignore
  validate(value, { target }) {
    const OVERSEAS_TERRITORIES_START = 97;
    const MONACO_STATE_START = 98;
    if (typeof target?.id !== 'undefined') {
      switch (target?.id) {
        case 'US':
          return (
            // eslint-disable-next-line no-magic-numbers
            value.length > 3 &&
            !new RegExp(/([0][0][6-9][0-9][0-9])/g).test(value)
          );
        case 'GB':
        case 'GG':
          return !new RegExp(/^(GY[1-9])/g).test(
            value.replace(/\s+/g, '') ||
              new RegExp(/^(JE[1-5])/g).test(value.replace(/\s+/g, ''))
          );
        case 'FR':
          // eslint-disable-next-line eqeqeq, no-magic-numbers
          return value.substring(0, 2) == OVERSEAS_TERRITORIES_START ||
            // eslint-disable-next-line eqeqeq, no-magic-numbers
            value.substring(0, 2) == MONACO_STATE_START
            ? false
            : true;
        case 'ES':
          return !new RegExp(/^(35|38|52)/g).test(value);
        case 'IT':
          return !new RegExp(/^(4789)/g).test(value);
        case 'FI':
          return !new RegExp(/(22|2200\d|220[1-9]\d|22[1-9]\d{2})/g).test(
            value
          );
        default:
          return true;
      }
    }
    return true;
  },
  message: (target, params) => {
    let message = '';
    switch (params._target_.id) {
      case 'US':
        message = 'We do not deliver to Puerto Rico yet.';
        break;
      case 'GB':
      case 'GG':
        message = 'We do not ship to Channel Islands.';
        break;
      case 'FR':
        message = 'Livraison uniquement en France Métropolitaine.';
        break;
      case 'ES':
        message = 'We do not deliver to the Canary Islands and to Melilla.';
        break;
      case 'IT':
        message = 'We do not deliver to San Marino.';
        break;
      case 'FI':
        message = 'We do not deliver to the Aland Islands.';
        break;
      default:
        message = 'We do not deliver to current address.';
    }
    return message;
  }
});
extend('name', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: nameRegex.test(value)
    };
  }
});

extend('validate-katakana', {
  message: 'Please enter in the full-width katakana.',
  validate(value) {
    return {
      valid: katakanaRegex.test(value)
    };
  }
});
extend('validate-hiragana', {
  message: 'Please use Hiragana only in this field.',
  validate(value) {
    return {
      valid: hiraganaRegex.test(value)
    };
  }
});
extend('validate-kana', {
  message: 'Please use full width kana only in this field.',
  validate(value) {
    return {
      valid: kanaRegex.test(value)
    };
  }
});

extend('postcode-jp', {
  message: 'Provided Zip/Postal Code seems to be invalid.',
  validate(value) {
    return {
      valid: postcodeJP.test(value)
    };
  }
});

export default defineComponent({
  name: 'ShippingAddressStep',
  components: {
    SfHeading,
    SfInput,
    SfSelect,
    ValidationProvider,
    ValidationObserver,
    VaimoPhone,
    LoyaltyPushRenderer,
    VaimoAddresses: () => import('organisms/VaimoAddresses.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoLoader: () =>
      import('~/diptyqueTheme/components/atoms/VaimoLoader.vue'),
    Billing: () =>
      import('@/diptyqueTheme/modules/checkout/components/Billing.vue'),
    OrderSummaryWithTopBar: () =>
      import(
        '@/diptyqueTheme/modules/checkout/components/OrderSummaryWithTopBar.vue'
      ),
    EditEmail: () =>
      import('@/diptyqueTheme/modules/checkout/components/EditEmail.vue')
  },
  scrollToTop: true,
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { isZhHkStore, isJpStore, isUsCaStore, getNameKanaRules } =
      useStore();
    const userShipping = ref<Customer | null>(null);
    const {
      load: loadShipping,
      save: saveShipping,
      loading: isShippingLoading
    } = useShipping();
    const { load: loadUserShipping, setDefaultAddress } = useUserAddress();
    const countryStore = useCountriesStore();
    const { search: searchCountry } = useCountrySearch();
    const selectedShippingMethodStore = useSelectedShippingMethodStore();
    const countries: ComputedRef<any[]> = computed(
      () => countryStore.allowedCountries
    );
    const country = ref<Country | null>(null);
    const { isAuthenticated, user, load: loadUser, updateUser } = useUser();
    const userMobile = ref();
    const shippingDetails = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm()
    );
    const { getRules } = useRules();
    const shippingMethods = ref<AvailableShippingMethod[]>([]);
    const shippingMethodsStore = useAvailableShippingMethodsStore();
    const currentAddressId = ref<number | null>(null);
    const {
      save: saveUserAddress,
      load: loadUserAddresses,
      loading: loadingUserAddresses
    } = useAddresses();
    const customerStore = useCustomerStore();
    const billingComponentRef = ref(null);
    const googleInputValue = ref(null);
    const { getAddDeliveryAddressDetails } = useGoogleTagManager();
    const createdAddressId = ref(null);
    const showGoogleAddress = ref(false);
    const showValidationMessage = ref(false);
    const shippingBoundToLocalStorage = ref({});
    const isSubmitting = ref(false);
    const isPendingForward = ref(false);
    const isBillingAddressFinished = ref(false);
    const addressSetFinished = ref(true);
    const chosenShippingAddress = ref(null);
    const isShippingChangeTriggered = ref(false);
    const isSetAsDefaultRequested = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(!isAuthenticated.value);
    const { config } = useConfig();
    const isGoogleSuggestionsActive = config.value?.google_suggestions_active;
    const googleApiKey = config.value.google_api_key;
    const isShippingDetailsStepCompleted = ref(false);
    const isHandlingNewShippingAddressSave = ref(false);
    const { searchZipCode, zip2AddressData } = useZip2Address();
    const { setDefaultPostCode } = usePostcode();
    const { applyCustomTabFocus } = useCustomTabFocus();
    const {
      hasOneBooxiItemInCart,
      sendBooxiExpiredMessage,
      checkIsBooxiExpired
    } = useBooxi();
    const {
      mask: phoneMask,
      maxLength: phoneLength,
      placeholder: phonePlaceholder
    } = usePhoneMask();
    const customMessages = {
      required: 'Mandatory input'
    };
    const maxCharacters = 30;
    const CHECK_LOADING = 100;
    const vaimoPhoneRef = ref(null);

    const isDeStore = computed(
      () => app.i18n.localeProperties.code === 'de_eu'
    );

    const isPhoneMaskWitFlagEnabled = computed(
      () => config.value.phone_mask_with_flag_enabled
    );
    const phoneKey = ref(0);

    const isPhoneValid = ref(true);

    // Check if current billing is valid
    isBillingAddressFinished.value =
      billingComponentRef.value && billingComponentRef.value.isBillingValid
        ? billingComponentRef.value.isBillingValid
        : false;

    const isBillingValid = ref(true);

    const onBillingPhoneChanged = (value) => {
      isBillingValid.value = value;
    };

    const nameKanaRules = getNameKanaRules();
    const zip2Address = async () => {
      if (postcodeJP.test(shippingDetails.value.postcode)) {
        await searchZipCode(shippingDetails.value.postcode);
        if (Object.keys(zip2AddressData.value).length) {
          let selectedRegion = availableRegions.value.find((item) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item.name.includes(zip2AddressData.value.region)
          );
          shippingDetails.value.region = selectedRegion;
          shippingDetails.value.region_id = selectedRegion?.region_id ?? null;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          shippingDetails.value.city = zip2AddressData.value.city;
        }
      }
    };

    const changeVisibility = () => {
      showGoogleAddress.value = !showGoogleAddress.value;
    };

    const trimString = (originalString) =>
      originalString.length > maxCharacters
        ? originalString.substring(0, maxCharacters)
        : originalString;

    // Trick to overwrite autocomplete attribute value for google suggestion field
    const noAutocomplete = ref('nope');
    const setAutocomplete = () => {
      setTimeout(() => {
        if (noAutocomplete.value === 'nope') {
          noAutocomplete.value = 'novalue';
        } else {
          noAutocomplete.value = 'nope';
        }
      });
    };

    const getGoogleSuggestion = (event) => {
      const googleSuggestionCountriesList = countriesList.value.map(
        (country) => country.id
      );

      let places =
        google &&
        new google.maps.places.Autocomplete(event.target, {
          fields: ['address_components', 'geometry', 'formatted_address'],
          types: ['geocode'],
          componentRestrictions: {
            country:
              Array.isArray(googleSuggestionCountriesList) &&
              // eslint-disable-next-line no-magic-numbers
              googleSuggestionCountriesList.length &&
              // eslint-disable-next-line no-magic-numbers
              googleSuggestionCountriesList.length < 6
                ? googleSuggestionCountriesList
                : []
          }
        });
      places.addListener('place_changed', () => {
        let place = places.getPlace();
        googleInputValue.value = place?.formatted_address;
        if (shippingDetails.value.street) {
          shippingDetails.value.street = '';
        }
        if (shippingDetails.value.postcode) {
          shippingDetails.value.postcode = '';
        }
        if (typeof place !== 'undefined') {
          showGoogleAddress.value = true;
          /*
           * Solution for changing the order of types and setting the country_code first
           * */
          const countryComponent = place.address_components.find((o) =>
            o.types.includes('country')
          ); // ~30% faster than "for" loop used before

          const waitForRegionsLoad = async () => {
            if (
              countryComponent &&
              googleSuggestionCountriesList?.length &&
              googleSuggestionCountriesList.includes(
                countryComponent.short_name.toUpperCase()
              )
            ) {
              shippingDetails.value.country_code = countryComponent.short_name;
              await setRegions(shippingDetails.value.country_code); // set available regions if "shippingDetails.value.country_code" is available
            } else {
              shippingDetails.value.country_code = '';
            }
          };

          const fillInTheValues = async () => {
            await waitForRegionsLoad(); // Wait when regions are set for selected country - making sure that JS worked well and for loop that follows next has all data needed to prefill fields

            /*
             * Solution for the case where user account is created from a different store and Google suggestions do not include the "locality" type.
             * (check for shippingDetails.value.city.length < 1 in postal_town || sublocality_level_1 || sublocality)
             * */
            if (shippingDetails.value.city) {
              shippingDetails.value.city = '';
            }

            for (const component of place.address_components) {
              const componentType = component.types[0];
              // eslint-disable-next-line default-case
              switch (componentType) {
                case 'street_number': {
                  shippingDetails.value.street = `${component.long_name} `;
                  break;
                }

                case 'route': {
                  shippingDetails.value.street += component.short_name;
                  break;
                }

                case 'postal_code': {
                  shippingDetails.value.postcode = `${component.long_name}`;
                  break;
                }

                case 'postal_code_suffix': {
                  shippingDetails.value.postcode += `-${component.long_name}`;
                  break;
                }

                case 'locality':
                  shippingDetails.value.city = trimString(component.long_name);
                  break;

                case 'postal_town':
                  if (shippingDetails.value.city.length < 1) {
                    shippingDetails.value.city = trimString(
                      component.long_name
                    );
                  }
                  break;

                case 'sublocality_level_1':
                  if (shippingDetails.value.city.length < 1) {
                    shippingDetails.value.city = trimString(
                      component.long_name
                    );
                  }
                  break;

                case 'sublocality':
                  if (shippingDetails.value.city.length < 1) {
                    shippingDetails.value.city = trimString(
                      component.long_name
                    );
                  }
                  break;

                case 'administrative_area_level_1':
                  // TODO Add check for store CA & US
                  if (isStateFieldVisible.value) {
                    const selectedRegion = availableRegions.value.find(
                      (region) => region.region_code === component.short_name
                    );

                    if (selectedRegion) {
                      shippingDetails.value.region = selectedRegion;
                      shippingDetails.value.region_id =
                        selectedRegion?.region_id ?? null;
                    }
                  } else {
                    shippingDetails.value.region = component.long_name;
                  }

                  if (shippingDetails.value.city.length < 1) {
                    shippingDetails.value.city = trimString(
                      component.long_name
                    );
                  }
                  break;

                case 'administrative_area_level_2':
                  if (shippingDetails.value.city.length < 1) {
                    shippingDetails.value.city = trimString(
                      component.long_name
                    );
                  }
                  break;
              }
            }

            if (isRequiredFieldsFilled.value) {
              await triggerSubmit();
            }

            triggerPhoneValidation();
          };

          fillInTheValues();
        }
      });

      setAutocomplete();
    };

    const addresses = computed(
      () =>
        customerStore.user?.addresses.filter(
          (address) =>
            typeof countriesList.value.find(
              (country) => country?.id === address.country_code
            ) !== 'undefined'
        ) ?? []
    );

    const canMoveForward = computed(
      () =>
        !isShippingLoading.value &&
        shippingDetails.value &&
        Object.keys(shippingDetails.value).length > 0
    );

    const hasSavedShippingAddress = computed(() => {
      if (!isAuthenticated.value || !userShipping.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = computed(() => {
      return countries.value;
    });

    const getCountryEntry = computed(() => {
      const fullCountry = countries.value?.find(
        (c) => c.id === shippingDetails.value.country_code
      )?.full_name_locale;
      return {
        abbreviation: shippingDetails.value.country_code,
        label: fullCountry
      };
    });
    /* Could be the cause
   *  let shippingDetailsData = {
        ...setDefaultPostCode(shippingDetails.value),
        customerAddressId: addressId,
        save_in_address_book: false
      };
   * */
    const saveShippingAddressData = async () => {
      const addressId = currentAddressId.value;
      const shippingDetailsData = {
        shippingDetails: addressFromCustomerToShippingCart({
          ...setDefaultPostCode(shippingDetails.value),
          customerAddressId: addressId === null ? null : String(addressId),
          save_in_address_book: false
        })
      };
      await saveShipping({
        shippingDetails: shippingDetailsData,
        customQuery: {
          setShippingAddressesOnCart: 'customSetShippingAddressesOnCart'
        }
      });
    };

    const handleNewShippingAddressSave = async (
      invalid: boolean,
      reset: () => void
    ) => {
      if (!isPhoneValid.value) {
        triggerPhoneValidation();
        return;
      }

      if (invalid) {
        return;
      }

      if (isHandlingNewShippingAddressSave.value) return;
      isHandlingNewShippingAddressSave.value = true;
      const shippingAddress = {
        address_book_type: 2,
        company: shippingDetails.value.company,
        city: shippingDetails.value.city,
        country_code: shippingDetails.value.country_code,
        default_shipping: false,
        default_billing: false,
        firstname: shippingDetails.value.firstname,
        lastname: shippingDetails.value.lastname,
        postcode: shippingDetails.value.postcode,
        street: [
          shippingDetails.value.street,
          shippingDetails.value.apartment || ''
        ],
        telephone: shippingDetails.value.telephone,
        region: isStateFieldVisible.value
          ? {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              region: shippingDetails.value.region.name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              region_id: shippingDetails.value.region.region_id
            }
          : null
      };

      if (isJpStore.value) {
        shippingAddress['lastnamekana'] = shippingDetails.value.lastnamekana;
        shippingAddress['firstnamekana'] = shippingDetails.value.firstnamekana;
      }

      await saveShippingAddressData();
      await createAddress(shippingAddress);
      //@ts-ignore
      await handleSetCurrentAddress({
        ...shippingAddress,
        id: createdAddressId.value
      });
      isHandlingNewShippingAddressSave.value = false;
      reset();
    };

    const updateUserAddressesStore = async () => {
      const userAddresses = await loadUserAddresses({
        getCustomerAddresses: 'customerAddresses'
      });
      customerStore.user.addresses = userAddresses;
    };

    const createAddress = async (address) => {
      const result = await saveUserAddress({ address: address });
      if (result.id) {
        createdAddressId.value = result.id;
        await updateUserAddressesStore();
      } else {
        // TODO: Error catch
        console.error('Something went wrong with createAddress;');
      }
    };

    const updateAddress = async (address) => {
      if (!address) {
        return false;
      }

      shippingDetails.value = {
        ...addressFromApiToForm(address)
      };

      await handleAddressSubmit()();
    };

    const handleAddressSubmit = (reset?: () => void) => async () => {
      const addressId = currentAddressId.value;
      let chosenAddr = null;
      isSubmitting.value = true;

      if (isAuthenticated.value && addresses.value && addressId) {
        chosenAddr = addresses.value.find((obj) => obj.id === addressId);
      }

      let shippingDetailsData = {
        ...shippingDetails.value,
        customerAddressId: addressId,
        save_in_address_book: false
      };

      if (chosenAddr !== null) {
        shippingDetailsData = {
          ...chosenAddr,
          customerAddressId: addressId,
          save_in_address_book: false
        };

        chosenShippingAddress.value = shippingDetailsData;
      }
      //same here
      setDefaultPostCode(shippingDetailsData);

      await mergeItem('checkout', { 'shipping-address': shippingDetailsData });
      await mergeItem('vsf-checkout', {
        'shipping-address': shippingDetailsData
      });
      let shippingInfo = await saveShipping({
        shippingDetails: !isStateFieldVisible.value
          ? shippingDetailsData
          : {
              ...shippingDetailsData,
              region:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                shippingDetails.value?.region?.region_code ??
                shippingDetails.value?.region,
              region_id:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                shippingDetails.value?.region?.region_id ??
                shippingDetails.value?.region_id
            },
        customQuery: {
          setShippingAddressesOnCart: 'customSetShippingAddressesOnCart'
        }
      });

      if (
        Object.keys(shippingInfo).length > 0 &&
        !shippingInfo?.available_shipping_methods
      ) {
        shippingInfo = await saveShipping({
          shippingDetails:
            addressFromCustomerToShippingCart(shippingDetailsData),
          customQuery: {
            setShippingAddressesOnCart: 'customSetShippingAddressesOnCart'
          }
        });
      }
      const isTestMode = lsGet('test_mode');
      if (isTestMode) {
        console.info('Test mode enabled');
        shippingMethodsStore.setShippingMethods([]);
        selectedShippingMethodStore.setShippingMethod(null);
      } else {
        shippingMethodsStore.setShippingMethods(
          shippingInfo?.available_shipping_methods ?? []
        );
        selectedShippingMethodStore.setShippingMethod(
          shippingInfo?.selected_shipping_method
        );
      }

      if (addressId !== null && isSetAsDefaultRequested.value) {
        const [chosenAddress] = userShippingGetters.getAddresses(
          userShipping.value,
          { id: addressId }
        );
        chosenAddress.default_shipping = isSetAsDefaultRequested.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userShipping.value = await loadUserShipping(true);
        }
      }

      // hack: update customers phone
      if (
        isAuthenticated.value &&
        userMobile.value &&
        userMobile.value === '0000000000'
      ) {
        let userData = {
          firstname: shippingDetails.value.firstname,
          lastname: shippingDetails.value.lastname,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mobile: shippingDetails.value.telephone
        };

        if (isJpStore.value) {
          userData['firstnamekana'] = shippingDetails.value.firstnamekana;
          userData['lastnamekana'] = shippingDetails.value.lastnamekana;
        }

        await updateUser({
          user: userData
        });
      }
      isShippingDetailsStepCompleted.value = true;
      isSubmitting.value = false;
    };

    const preSelectRegion = () => {
      if (
        availableRegions.value &&
        typeof shippingDetails.value.region === 'string'
      ) {
        const regionObject = availableRegions.value.find(
          (obj) => obj['region_code'] === shippingDetails.value.region
        );
        shippingDetails.value.region = regionObject;
      }
    };

    const shippingDetailsBeforeNewAddress = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm()
    );
    const addressIdBeforeNewAddress = ref<number | null>(null);
    const handleAddNewAddressBtnClick = () => {
      addressIdBeforeNewAddress.value = currentAddressId.value;
      currentAddressId.value = null;
      shippingDetailsBeforeNewAddress.value = { ...shippingDetails.value };
      shippingDetails.value = getInitialCheckoutAddressForm();
      phoneKey.value++;
      if (isUsCaStore.value || isJpStore.value) preSelectCountry();
      isAddNewAddressFormVisible.value = true;
      isShippingDetailsStepCompleted.value = false;
    };

    const handeCancelAddNewAddress = async () => {
      currentAddressId.value = addressIdBeforeNewAddress.value;
      shippingDetails.value = { ...shippingDetailsBeforeNewAddress.value };
      isAddNewAddressFormVisible.value = false;
      await handleAddressSubmit()();
    };

    const goToShippingStep = async (resetFormCB): Promise<void> => {
      if (checkIsBooxiExpired()) {
        sendBooxiExpiredMessage();
        return;
      }
      resetFormCB();
      if (isRequiredFieldsFilled.value) {
        if (!hasOneBooxiItemInCart.value) {
          setTimeout(async () => {
            const checkShippingLoading = async () => {
              // Wait until isShippingLoading becomes false
              if (!isShippingLoading.value) {
                await billingComponentRef.value.handleAddressSubmit();
              } else {
                setTimeout(checkShippingLoading, CHECK_LOADING); // Check again after 100 milliseconds
              }
            };
            await checkShippingLoading();
            // eslint-disable-next-line no-magic-numbers
          }, 2500);
        } else {
          billingComponentRef.value.handleAddressSubmit();
        }

        // proceed only if billing address is valid
        if (
          billingComponentRef.value &&
          (billingComponentRef.value.isBillingValid ||
            billingComponentRef.value?.sameAsShipping)
        ) {
          isPendingForward.value = true;
          app.$gtm.push(getAddDeliveryAddressDetails());
          /** Push to payment when a VirtualProduct is in the cart,
           * as no shipping step is required for VirtualProduct. */
          hasOneBooxiItemInCart.value
            ? router.push(app.localeRoute({ name: 'payment' }))
            : router.push(app.localeRoute({ name: 'shipping' }));
          isPendingForward.value = false;
        } else {
          isBillingAddressFinished.value = false;
        }
      }
    };

    const isRequiredFieldsFilled: ComputedRef<boolean> = computed<boolean>(
      () => {
        const requiredFields = [
          'firstname',
          'lastname',
          'telephone',
          'street',
          'city',
          'country_code'
        ];

        if (!isZhHkStore.value) {
          requiredFields.push('postcode');
        }
        if (isUsCaStore.value) {
          requiredFields.push('region_id');
        }
        if (isJpStore.value) {
          requiredFields.push('region');
          requiredFields.push('region_id');
          requiredFields.push('firstnamekana');
          requiredFields.push('lastnamekana');
        }
        const shippingDetailsLocal = { ...shippingDetails.value };
        return requiredFields.every((field) => !!shippingDetailsLocal[field]);
      }
    );

    const SUBMIT_DEBOUNCE = 1500;
    const triggerSubmit = debounce(async (): Promise<void> => {
      const checkLoading = async () => {
        if (!isShippingLoading.value) {
          isShippingChangeTriggered.value = true;
          isShippingDetailsStepCompleted.value = false;
          try {
            shippingMethodsStore.isPending = true;
            Logger.info(
              'Initiate the shipping methods query with the prepared fields.'
            );
            await handleAddressSubmit()();
            Logger.info('Shipping methods have been successfully set.');
          } catch (e) {
            Logger.error('triggerSubmit', e);
          } finally {
            shippingMethodsStore.isPending = false;
            isShippingDetailsStepCompleted.value = false;
            isShippingChangeTriggered.value = false;
          }
          shippingMethodsStore.isPending = false;
        } else {
          setTimeout(checkLoading, CHECK_LOADING); // Check again after 100 milliseconds
        }
      };
      await checkLoading();
    }, SUBMIT_DEBOUNCE);

    const handleSetCurrentAddress = async (
      customerAddress: CustomerAddress
    ) => {
      const id = customerAddress?.id;
      currentAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }
      shippingDetails.value = addressFromApiToForm(customerAddress);

      country.value = customerAddress.country_code
        ? countriesList.value?.find(
            (country) => country.id === customerAddress.country_code
          )
        : null;
      isShippingDetailsStepCompleted.value = false;
      isAddNewAddressFormVisible.value = false;

      const shippingDetailsData = {
        ...customerAddress,
        customerAddressId: id,
        save_in_address_book: false
      };

      await mergeItem('checkout', { 'shipping-address': shippingDetailsData });
      chosenShippingAddress.value = customerAddress;
      await triggerSubmit();
      if (billingComponentRef.value?.sameAsShipping) {
        await billingComponentRef.value.handleAddressSubmit();
        addressSetFinished.value = true;
      } else {
        isBillingAddressFinished.value = false;
      }
    };

    const changeShippingDetails = async (
      field: keyof CheckoutAddressForm,
      value: string
    ) => {
      shippingDetails.value[field] = value;
      isShippingDetailsStepCompleted.value = false;
      currentAddressId.value = null;
      if (isJpStore.value && field == 'postcode') {
        await zip2Address();
      }
      if (isRequiredFieldsFilled.value) {
        await triggerSubmit();
      }
    };

    const showGoogleAddressForm = () => {
      if (!showGoogleAddress.value) showGoogleAddress.value = true;
    };

    const SET_TO_LS_DEBOUNCE = 1500;
    const setFieldsToLS = debounce(async (): Promise<void> => {
      await mergeItem('checkout', {
        'shipping-address': shippingDetails.value
      });
    }, SET_TO_LS_DEBOUNCE);

    const changeCountry = async (selectedCountry) => {
      changeShippingDetails('country_code', selectedCountry);
      const newCountry = await searchCountry({ id: selectedCountry });
      shippingDetails.value.region = null;
      shippingDetails.value.region_id = null;
      country.value = newCountry;
      setRegions(selectedCountry);
    };

    const updateRegion = async (desiredRegionId) => {
      if (!availableRegions.value || !desiredRegionId) {
        return;
      }

      const foundState =
        availableRegions.value &&
        availableRegions.value.find(
          (state) => state.region_id === +desiredRegionId
        );

      if (foundState) {
        shippingDetails.value.region = foundState;
        shippingDetails.value.region_id = +desiredRegionId;
        if (isRequiredFieldsFilled.value) {
          await triggerSubmit();
          if (billingComponentRef.value?.sameAsShipping) {
            billingComponentRef.value.handleAddressSubmit();
          } else {
            isBillingAddressFinished.value = false;
          }
        }
      }
    };

    const checkValidityAndManipulateWithAddressForm = (dirty, invalid) => {
      if (dirty) {
        showGoogleAddress.value = true;
      }

      if (invalid) {
        showValidationMessage.value = true;
        showGoogleAddress.value = true;
      }

      if (dirty || invalid) {
        triggerPhoneValidation();
      }
    };

    const isCountryAvailable = (
      address: CustomerAddress | CartAddressInterface
    ): boolean => {
      return (
        typeof countriesList.value.find(
          (country) =>
            country?.id === (address as CustomerAddress)?.country_code ??
            (address as CartAddressInterface)?.country?.code
        ) !== 'undefined'
      );
    };

    onMounted(async () => {
      window.scroll(0, 0);
      selectedShippingMethodStore.selectedShippingMethodData = null;
      const vsfCheckoutData = lsGet('vsf-checkout');
      shippingBoundToLocalStorage.value =
        vsfCheckoutData && vsfCheckoutData['shipping-address'];
      const [loadedShippingInfoBoundToCart, loadedUserShipping] =
        await Promise.all([
          loadShipping(),
          loadUserShipping(),
          countryStore.getAllowedCountries()
        ]);
      lsSet('loaded_user_addresses', loadedUserShipping);
      const [defaultAddress = null] = userShippingGetters.getAddresses(
        loadedUserShipping,
        { default_shipping: true }
      );

      const wasShippingAddressAlreadySetOnCart = Boolean(
        loadedShippingInfoBoundToCart
      );

      let userAddressIdenticalToSavedCartAddress;

      if (shippingBoundToLocalStorage.value) {
        userAddressIdenticalToSavedCartAddress =
          findUserAddressIdenticalToSavedCartAddress(
            loadedUserShipping?.addresses,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            shippingBoundToLocalStorage.value
          );
        if (isCountryAvailable(shippingBoundToLocalStorage.value)) {
          shippingDetails.value =
            shippingBoundToLocalStorage.value as CheckoutAddressForm;
        }
      } else if (wasShippingAddressAlreadySetOnCart) {
        const shippingInfoBoundToCart = { ...loadedShippingInfoBoundToCart };

        if (!shippingInfoBoundToCart.company) {
          shippingInfoBoundToCart.company = undefined;
        }
        shippingInfoBoundToCart.region = undefined;

        userAddressIdenticalToSavedCartAddress =
          findUserAddressIdenticalToSavedCartAddress(
            loadedUserShipping?.addresses,
            shippingInfoBoundToCart
          );
      }
      if (
        userAddressIdenticalToSavedCartAddress &&
        isCountryAvailable(userAddressIdenticalToSavedCartAddress)
      ) {
        await handleSetCurrentAddress({
          ...userAddressIdenticalToSavedCartAddress,
          id: userAddressIdenticalToSavedCartAddress?.id
        });
      } else if (defaultAddress && isCountryAvailable(defaultAddress)) {
        await handleSetCurrentAddress(defaultAddress);
      } else if (
        shippingBoundToLocalStorage.value &&
        Object.keys(shippingBoundToLocalStorage.value).length &&
        isCountryAvailable(shippingBoundToLocalStorage.value)
      ) {
        shippingDetails.value = addressFromApiToForm(
          shippingBoundToLocalStorage.value
        );
      } else if (isRequiredFieldsFilled.value) {
        await triggerSubmit();
      }

      if (shippingDetails.value?.country_code) {
        country.value =
          countriesList.value?.find(
            (country) => country.id === shippingDetails.value.country_code
          ) ?? null;
      }
      userShipping.value = loadedUserShipping;

      preSelectCountry(); // preselect one of available countries and set regions if it's US or Canada

      if (!addresses.value.length) {
        isAddNewAddressFormVisible.value = true;

        preSelectRegion();
      }
      if (isAuthenticated.value) {
        if (!shippingDetails.value.firstname) {
          shippingDetails.value.firstname = user.value.firstname;
        }

        if (!shippingDetails.value.lastname) {
          shippingDetails.value.lastname = user.value.lastname;
        }

        if (!shippingDetails.value.telephone) {
          shippingDetails.value.telephone =
            user.value?.mobile === '0000000000' ? '' : user.value?.mobile;
        }
      } else {
        preSelectRegion();
      }

      if (addresses.value && addresses.value.length === 1) {
        handleSetCurrentAddress(addresses.value[0]);
      }

      applyCustomTabFocusForJP();
    });

    // Apply custom TAB focus order for JP store to fix the shuffled focus order
    // as JP form has changed shipping fields order with CSS "order" styles
    const applyCustomTabFocusForJP = () => {
      if (isJpStore.value) {
        // Shipping ADD-form TAB focus flow
        applyCustomTabFocus(
          [
            {
              from: '#firstnamekana',
              to: '#zipCode',
              toFallback: 'select[name="state"]'
            },
            { from: '#zipCode', to: 'select[name="state"]' },
            { from: 'select[name="state"]', to: '#city' },
            { from: '#city', to: '#streetName' },
            { from: '#streetName', to: '#apartment' },
            { from: '#apartment', to: '.vti__input' },
            {
              from: '.shipping-form .vti__input',
              to: '.shipping-form .shipping-address__actions button',
              toFallback: '.same-as-shipping-checkbox input',
              applyParent: false
            }
          ],
          '.shipping-form.form'
        );

        // Billing ADD-form TAB focus flow
        applyCustomTabFocus(
          [
            {
              from: '#firstnamekana',
              to: '#zipCode',
              toFallback: 'select[name="state"]'
            },
            { from: '#zipCode', to: 'select[name="state"]' },
            { from: 'select[name="state"]', to: '#city' },
            { from: '#city', to: '#streetName' },
            { from: '#streetName', to: '#apartment' },
            { from: '#apartment', to: '.vti__input' },
            {
              from: '.billing-form .vti__input',
              to: '.billing-form .form__action button',
              toFallback: '.form__action-button button',
              applyParent: false
            }
          ],
          '.billing-form.form'
        );
      }
    };

    const shippingCountry = computed({
      get() {
        return countriesList.value.find(
          (country) => country.id === shippingDetails.value.country_code
        );
      },
      set(newValue) {
        nextTick(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          shippingDetails.value.country_code = newValue.id;
        });
      }
    });

    const shippingRegion = computed({
      get() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return availableRegions.value.find(
          (region) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            region.region_code === shippingDetails.value.region?.region_code
        );
      },
      set(newValue) {
        nextTick(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          shippingDetails.value.region = newValue;
        });
      }
    });

    const storeCode = computed(() => app.i18n.localeProperties.code);
    const isStateFieldVisible = computed(
      () =>
        storeCode.value === 'en_us' ||
        storeCode.value === 'fr_us' ||
        storeCode.value === 'ja_jp'
    );
    const availableRegions = ref([]);
    const setRegions = (countryId: string) => {
      availableRegions.value = (
        countries.value.find((country) => country.id === countryId)
          ?.availableRegions || []
      ).reduce((memo, cur) => {
        memo.push({
          region_code: cur.code,
          name: cur.name,
          region_id: cur.id
        });
        return memo;
      }, []);
    };

    const preSelectCountry = () => {
      let countryId = '';

      if (shippingDetails.value.country_code !== '') {
        if (shippingDetails.value.country_code === 'US') {
          countryId = 'US';
        } else if (shippingDetails.value.country_code === 'CA') {
          countryId = 'CA';
        } else if (shippingDetails.value.country_code === 'JP') {
          countryId = 'JP';
        } else {
          return;
        }

        setRegions(countryId);
      } else {
        if (storeCode.value === 'en_us') {
          countryId = 'US';
        } else if (storeCode.value === 'fr_us') {
          countryId = 'CA';
        } else if (storeCode.value === 'ja_jp') {
          countryId = 'JP';
        } else {
          return;
        }

        shippingDetails.value.country_code = countryId;
        setRegions(countryId);
      }
    };

    useFetch(async () => {
      if (!user.value) {
        await loadUser({
          customQuery: {
            customer: 'customerWithOptions'
          }
        });
      }
      if (isAuthenticated.value && user.value?.mobile) {
        userMobile.value = user.value?.mobile;
      }
    });

    const phoneValidHandler = (value) => {
      isPhoneValid.value = value;
    };

    const triggerPhoneValidation = () => {
      vaimoPhoneRef.value.onFocus();
    };

    return {
      isZhHkStore,
      isDeStore,
      isSubmitting,
      isPendingForward,
      customMessages,
      shippingCountry,
      shippingRegion,
      isAddNewAddressFormVisible,
      canMoveForward,
      isStateFieldVisible,
      changeCountry,
      updateRegion,
      changeShippingDetails,
      showGoogleAddressForm,
      countries,
      countriesList,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      hasSavedShippingAddress,
      isAuthenticated,
      isFormSubmitted,
      isShippingDetailsStepCompleted,
      isShippingLoading,
      searchCountry,
      isSetAsDefaultRequested,
      shippingDetails,
      shippingMethods,
      addresses,
      getGoogleSuggestion,
      showGoogleAddress,
      changeVisibility,
      isGoogleSuggestionsActive,
      googleApiKey,
      saveShippingAddressData,
      handleNewShippingAddressSave,
      isHandlingNewShippingAddressSave,
      getCountryEntry,
      billingComponentRef,
      checkValidityAndManipulateWithAddressForm,
      googleInputValue,
      chosenShippingAddress,
      showValidationMessage,
      availableRegions,
      updateAddress,
      isRequiredFieldsFilled,
      goToShippingStep,
      isBillingAddressFinished,
      handeCancelAddNewAddress,
      triggerSubmit,
      isUsCaStore,
      addressSetFinished,
      noAutocomplete,
      setAutocomplete,
      getRules,
      phoneMask,
      phoneLength,
      phonePlaceholder,
      isJpStore,
      loadingUserAddresses,
      isPhoneMaskWitFlagEnabled,
      nameKanaRules,
      config,
      phoneKey,
      hasOneBooxiItemInCart,
      isPhoneValid,
      phoneValidHandler,
      isBillingValid,
      onBillingPhoneChanged,
      vaimoPhoneRef
    };
  },
  head() {
    return {
      //tmp hardcoded key
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA9WegL0HBtfImQohLuGIJA_xF6Iwe6OAM&libraries=places`
        }
      ]
    };
  }
});
