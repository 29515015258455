
































































import {
  defineComponent,
  computed,
  ref,
  useContext,
  useAsync,
  watch
} from '@nuxtjs/composition-api';
import { useProduct, useImage } from '~/composables';
import { Product } from '~/modules/catalog/product/types';
import { useAddToCart } from '~/helpers/cart/addToCart';
import VaimoProductCard from 'organisms/VaimoProductCard.vue';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';

export default defineComponent({
  name: 'VaimoProductGrid',
  components: {
    VaimoProductCard
  },
  props: {
    productsSku: {
      required: true,
      type: Array
    },
    showModel: {
      required: false,
      type: Boolean,
      default: false
    },
    productsList: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { app } = useContext();
    const { getViewItemListDetails, getSelectItemDetails } =
      useGoogleTagManager();
    const { getMagentoImage } = useImage();
    const { getProductList, loading: productsLoading } = useProduct();
    const {
      addItemToCart,
      isInCart,
      loading: addToCartLoading
    } = useAddToCart();

    const productGroups = computed(() => {
      const _productsSKU = props.productsSku.filter((p) => p);
      if (typeof _productsSKU[0] === 'string') {
        return {
          groupTitle: 'Unnamed group',
          sku: _productsSKU
        };
      }
      return _productsSKU;
    });

    const activeGroup = ref();
    const activeGroupTitle = computed(() => activeGroup.value?.groupTitle);
    const activeGroupSKU = computed(() => activeGroup.value?.sku);
    const setActiveGroup = function (group) {
      activeGroup.value = group;
    };

    watch(activeGroup, async () => {
      if (activeGroupSKU.value.length) {
        await getProductsBySku(activeGroupSKU.value);

        if (products.value) {
          app.$gtm.push(getViewItemListDetails(products.value, 'slider'));
        }
      }
    });

    const products = ref();
    const getProductsBySku = async (sku_array: string[]) => {
      if (props.productsList?.length) {
        products.value = props.productsList;
        return;
      }
      try {
        const data = await getProductList({
          filter: {
            sku: {
              in: sku_array
            }
          }
        });
        products.value = data?.items.sort((item1, item2) => {
          const index1 = sku_array.indexOf(item1.sku);
          const index2 = sku_array.indexOf(item2.sku);
          return index1 - index2;
        });
      } catch (e) {
        console.error(e);
      }
    };

    const getRelatedProductsQnty = (product: Product): number | null => {
      if (!product || !product['revamp_related_products']) {
        return null;
      }

      const relatedProducts = product['revamp_related_products'];
      const qty = relatedProducts.length > 1 ? relatedProducts.length - 1 : 0;

      return qty;
    };

    const getProductCapacity = (product: Product): string | null => {
      if (!product || !product['capacity']) {
        return null;
      }

      const capacity = product['capacity'];
      return capacity[0]?.label ?? null;
    };
    const getProductVariantName = (product: Product): string | null => {
      if (!product || !product['variant_name']) {
        return null;
      }

      const variant_name = product['variant_name'];
      return variant_name[0]?.label ?? null;
    };

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    const addToCartLoadingSKU = ref();
    const addToCart = (item) => {
      addToCartLoadingSKU.value = item.product.sku;
      addItemToCart(item);
    };

    const getAddToCartLoadingFlag = (product) => {
      return (
        addToCartLoading.value && addToCartLoadingSKU.value === product.sku
      );
    };

    useAsync(async () => {
      setActiveGroup(productGroups.value[0]);
    });

    const onGtmSelectItem = (product: Product, index: number, event): void => {
      // Prevents GTM event from firing when clicking on the add to cart button
      if (event.target.closest('.vaimo-product-card__add-to-cart')) {
        return;
      }

      app.$gtm.push(getSelectItemDetails(product, index, 'slider'));
    };

    return {
      productGetters,
      customProductGetters,
      productGroups,
      activeGroupTitle,
      setActiveGroup,
      products,
      productsLoading,
      getProductLink,
      getMagentoImage,
      onGtmSelectItem,
      addToCart,
      isInCart,
      addToCartLoading,
      addToCartLoadingSKU,
      getAddToCartLoadingFlag
    };
  }
});
