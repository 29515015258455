























































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref
} from '@nuxtjs/composition-api';

import { useStore } from '~/composables';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import useAddresses from '~/modules/customer/composables/useAddresses';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'VaimoAddresses',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoAddEditShippingAddress: () =>
      import('organisms/VaimoAddEditShippingAddress.vue'),
    VaimoJpAddresses: () => import('organisms/VaimoJpAddresses.vue')
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    addresses: {
      type: Array,
      required: true
    },
    addressType: {
      type: String,
      required: false,
      default: ''
    },
    chosenAddress: {
      type: [Object, String, null],
      default: null,
      required: false
    }
  },
  emits: ['deleteAddress', 'setCurrentAddress', 'updateAddress'],
  setup(props, { emit }) {
    const { isJpStore } = useStore();
    const customerStore = useCustomerStore();
    const { setMask } = usePhoneMask();
    const {
      remove: deleteUserAddress,
      update: updateUserAddress,
      save: saveUserAddress,
      load: loadUserAddresses,
      loading: loadingUserAddresses
    } = useAddresses();
    const countriesStore = useCountriesStore();
    const { isHkMoCode } = usePostcode();
    const SCROLL_OFFSET = -99999;

    const updateUserAddressesStore = async () => {
      const userAddresses = await loadUserAddresses({
        getCustomerAddresses: 'customerAddresses'
      });
      customerStore.user.addresses = [...userAddresses];
    };

    const addresses = ref([]);
    const addressesList = computed(() => {
      addresses.value = props.addresses
        ? JSON.parse(JSON.stringify(props.addresses))
        : [];
      return addresses.value.filter(
        (address) =>
          !address?.address_book_type ||
          address?.address_book_type === props.addressType ||
          address?.address_book_type === 'default'
      );
    });
    const activeAddress = ref(null);
    const activeAddressId = computed(() => activeAddress.value?.id);
    const shownFormType = ref(null);
    const addressIDForDelete = ref('');

    const addAddress = () => {
      activeAddress.value = null;
      shownFormType.value = shownFormType.value === 'create' ? null : 'create';
    };

    const editAddress = (address) => {
      if (shownFormType.value === 'edit') {
        if (address.id === activeAddress.value?.id) {
          activeAddress.value = null;
          shownFormType.value = null;
        } else {
          activeAddress.value = address;
        }
      } else {
        activeAddress.value = { ...address };
        shownFormType.value = 'edit';
      }
    };

    const deleteAddress = async (address) => {
      addressIDForDelete.value = address?.id;
      try {
        const result = await deleteUserAddress({
          address: { id: address?.id }
        });
        if (result === true) {
          await updateUserAddressesStore();
        }
      } catch (err) {
        console.error(err);
      } finally {
        addressIDForDelete.value = '';
      }
    };

    const createAddress = async (address) => {
      const result = await saveUserAddress({ address: address });
      if (result.id) {
        shownFormType.value = null;
        await updateUserAddressesStore();
      } else {
        // TODO: Error catch
        console.error('Something went wrong with createAddress;');
      }
    };

    const updateAddress = async (address) => {
      const result = await updateUserAddress({ address: address });
      if (result.id) {
        activeAddress.value = null;
        shownFormType.value = null;
        await updateUserAddressesStore();
        emit('updateAddress', address);
      } else {
        // TODO: Error catch
        console.error('Something went wrong with updateAddress;');
      }
    };

    const emitSetCurrentAddress = (addressId: number) => {
      const address = addresses.value.find(
        ({ id }) => id === Number(addressId)
      );
      if (address) {
        emit('setCurrentAddress', address);
      }
    };

    const transformAddressType = (type) => {
      const typesMap = {
        billing: 1,
        shipping: 2
      };
      return typesMap[type];
    };

    const saveForm = async (data) => {
      const _form = data.form;
      if (data.action === 'create') {
        _form.address_book_type = transformAddressType(props.addressType);
        await createAddress(_form);
      } else if (data.action === 'edit') {
        await updateAddress(_form);
      }
      window.scrollBy(0, SCROLL_OFFSET);
    };

    const getCountryNameByCode = (code) => {
      return countriesStore.countries?.find((country) => country.id === code)
        ?.full_name_locale;
    };

    const chosenAddressId = computed(() => {
      return props.chosenAddress?.id || false;
    });

    const isPostcodeVisible = (country) => {
      if (!country) return false;

      return !isHkMoCode(country);
    };

    onBeforeMount(async () => {
      await countriesStore.loadCountriesList();
    });

    return {
      isJpStore,
      addressesList,
      setMask,
      getCountryNameByCode,
      activeAddress,
      activeAddressId,
      shownFormType,
      loadingUserAddresses,
      addAddress,
      editAddress,
      deleteAddress,
      addressIDForDelete,
      saveForm,
      emitSetCurrentAddress,
      isPostcodeVisible,
      chosenAddressId
    };
  }
});
