<template>
  <SfLoader
    :loading="productsLoading && loadingType === 'spinner'"
    class="vaimo-products-wrapper"
  >
    <transition name="sf-fade" mode="out-in">
      <component
        :is="wrapperComponent"
        :items-length="itemsLength"
        v-bind="wrapperDisplayProps"
        class="vaimo-products"
        :class="wrapperClasses"
        :data-ukey="uniqueKey"
      >
        <VaimoProductCard
          :key="product.sku"
          :sku="product.sku"
          image-tag="nuxt-img"
          :loading="productsLoading"
          show-add-to-cart-button
          :sub-title="getProductPLPDescription(product)"
          :title="productGetters.getName(product)"
          :loading-flag="getAddToCartLoadingFlag(product)"
          :image="
            getMagentoImage(productGetters.getProductThumbnailImage(product))
          "
          :hover-image="getProductHoverImage(product)"
          :editorial-data="customProductGetters.getProductEditorial(product)"
          :regular-price="validateFraction(getRegularPrice(product))"
          :base-price="validateFraction(Number(productGetters.getBasePrice(product)))"
          :image-width="400"
          :image-height="500"
          :link="localePath(getProductLink(product))"
          :nuxt-img-config="{ fit: 'cover' }"
          :wishlist-icon="false"
          :is-added-to-cart="isInCart(product)"
          @addToCart="addToCart({ product, quantity: 1 })"
          @click.native="setClickedNostoSlotToStorage()"
          @keypress.native.enter="setClickedNostoSlotToStorage()"
        />
      </component>
    </transition>
  </SfLoader>
</template>

<script>
import { KlevuEvents } from '@klevu/core'
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg'
import { SfLoader, SfProductCard } from '@storefront-ui/vue';
import VaimoProductCard from '../../../diptyqueTheme/components/organisms/VaimoProductCard.vue';
import { useQuickSearch } from '../index'
import {
  defineComponent,
  computed,
  ref,
  useAsync,
  watch,
  useFetch,
  ssrRef,
  useContext
} from '@nuxtjs/composition-api';
import { useProduct, useImage } from '~/composables';
import { Product } from '~/modules/catalog/product/types';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import VaimoGrid from '~/diptyqueTheme/components/molecules/VaimoGrid.vue';
import groupTypes from '~/diptyqueTheme/config/productGroupTypes';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';

export default {
  components: {
    SfProductCard,
    VaimoProductCard,
    VaimoSlider,
    VaimoGrid,
    SfLoader
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { app } = useContext();
    const { getMagentoImage } = useImage();
    const { getProductList, loading: productsLoading } = useProduct();
    const quickSearchStore = useQuickSearch();

    const productClick = (product) => {
      KlevuEvents.searchProductClick(product, quickSearchStore.searchTerm);
    };
    //call click event for Klevu statictics
    productClick(props.product);

    const getProductImageUrl = (product) => {
      return product.imageUrl ? product.imageUrl : productPlaceholder;
    };

    const getProductUrl = (product) => `/p/${product.url.split('/p/')[1]}`;

    const mapProductToCT = (product) => {
      return {
        product: {
          sku: product.sku || ''
        },
        quantity: product.quantity || 1
      };
    };

    const addKlevuItemToCart = (product) => {
      const mappedProduct = mapProductToCT(product);
    };

    return {
      quickSearchStore,
      productClick,
      productPlaceholder,
      getProductImageUrl,
      getProductUrl,
      addKlevuItemToCart,
      validateFraction
    };
  }
};
</script>

<style lang="scss" scoped>
.result-card {
  margin: var(--spacer-sm) 0;
  @include for-desktop {
    margin: var(--spacer-2xs) 0;
  }
}
</style>
